import { getAidDomain, getCurrentUrl } from '../utils/url';
import { navigateTo } from '../utils/location';
import log from '../utils/log';
import {
  EmergencyMode,
  getActiveEmergencyModes,
  isInEmergencyMode,
} from '../utils/emergencyMode';
import store from '../store';

import thirdPartyCookie from './thirdPartyCookie';

export type Strategies = Array<{
  name: string;
  handler: () => Promise<boolean>;
}>;

const AUTOLOGIN_STORAGE_KEY = 'has-tried-autologin';
export const AUTOLOGIN_FALLBACK_SECONDS = 60000; // One minute

function inIframe() {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
}

function hasAlreadyAttemptedAutoLogin() {
  try {
    if (!window.sessionStorage) {
      return true; // Default to not attempt auto login, to avoid loops
    }
  } catch {
    // May throw security error if access to sessionStorage is denied by the user.
    return true; // Default to not attempt auto login, to avoid loops
  }

  try {
    const previousAutologinTimestamp = window.sessionStorage.getItem(
      AUTOLOGIN_STORAGE_KEY
    );
    if (previousAutologinTimestamp) {
      try {
        if (
          Date.now() - parseInt(previousAutologinTimestamp, 10) <
          AUTOLOGIN_FALLBACK_SECONDS
        ) {
          return true; // User has recently attempted auto login, don't engage loop
        }
      } catch {
        window.sessionStorage.removeItem(AUTOLOGIN_STORAGE_KEY);
        return true; // Don't know if user has attempted auto login, so assume it has to avoid loop
      }
    }
    window.sessionStorage.setItem(AUTOLOGIN_STORAGE_KEY, Date.now().toString());
    return false; // User has not recently attempted auto login, so we can safely attempt now.
  } catch (e) {
    log.warn(`Autologin not attempted due to exception: ${e}`);
  }
  return true; // Fallback to not attempt auto login, to avoid loops
}

const attemptAutologin = async (strategies: Strategies) => {
  if (typeof window === 'undefined' || inIframe()) {
    // Avoid auto-login on preview for dr edition and in workers.
    return;
  }

  if (store.get('state')?.isLoggedIn) {
    return;
  }

  if (isInEmergencyMode(EmergencyMode.Paywall)) {
    // Avoid auto-login if we are in aid emergency mode
    log.warn('Autologin prevented. We are in paywall emergency mode.', {
      emergencyModes: getActiveEmergencyModes(),
    });
    return;
  }

  for (const strategy of strategies) {
    const shouldTryMoreStrategies = await strategy
      .handler()

      .then((shouldPerformAutoLogin) => {
        if (shouldPerformAutoLogin) {
          if (hasAlreadyAttemptedAutoLogin()) {
            return false;
          }

          // Try to get newspaper cookie
          const url = new URL(`https://${getAidDomain()}/aid/logg_inn/auto`);
          url.searchParams.set('context', `auto_login_${strategy.name}`);
          url.searchParams.set('requestedUrl', getCurrentUrl());
          navigateTo(url.href);

          return new Promise<boolean>(() => {
            /**
             * Don't resolve autologin promise when navigating away.
             * This will cause waiting code to execute as if the user did/did not have access
             * and will in turn cause flickering and unnecessary (and often aborted) request to
             * various backends.
             */
          });
        }
        return true;
      });

    if (!shouldTryMoreStrategies) {
      return;
    }
  }
};

export const attemptAutoLoginJob = () =>
  attemptAutologin([
    {
      name: 'third_party',
      handler: thirdPartyCookie,
    },
  ]);

export const attemptPaywallAutoLogin = () =>
  attemptAutologin([
    {
      name: 'paywall',
      handler: () => Promise.resolve(true),
    },
  ]);
