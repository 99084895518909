import { State, UserAttributes } from '../types';
import { dispatch, listenForAllEvents } from '../utils/customEventWrapper';
import { AmediaUserEvents } from '../eventHandlers/eventMap';
import { deepEquals } from '../utils/deepEquals';
import { isRunningOnLocalhost } from '../utils/localhostCheck';
import FetchTimeoutError from '../exceptions/FetchTimeoutError';
import { ClientId, DEFAULT_CLIENT_ID } from '../clients/europa';
import { NamespaceList } from '../eventHandlers/storage';
import { EmergencyMode, isInEmergencyMode } from '../utils/emergencyMode';
import EmergencyModeError from '../exceptions/EmergencyModeError';

import { Namespace, ProxiedNamespace } from './Namespace';

type Millis = number;

const loaded = Date.now();

function isMissingAttributes(
  requestedKeys?: unknown[],
  data?: Record<string, unknown>
) {
  if (requestedKeys && requestedKeys.length > 0) {
    if (data && Object.keys(data).length > 0) {
      return (
        requestedKeys.filter((a: string) => !Object.keys(data).includes(a))
          .length > 0
      );
    }
    return true;
  }
  return false;
}

function isMissingClientStorage(
  requestedStorage: Record<ClientId, NamespaceList>,
  data: Record<ClientId, Record<string, never>>
) {
  if (Object.keys(requestedStorage).length > Object.keys(data).length) {
    return true;
  }
  for (const key in requestedStorage) {
    if (!data[key]) {
      // Missing specific client
      return true;
    }
    if (requestedStorage[key].filter((n) => !data[key][n]).length > 0) {
      // Missing specific namespace in specific client
      return true;
    }
  }
  return false;
}

const findMissingAttributes = (a: unknown[] | undefined, b?: unknown) =>
  a?.filter((e: string) => !Object.keys(b || []).includes(e)) || [];

const findMissingStorage = (
  requested: Record<ClientId, NamespaceList>,
  received: Record<ClientId, Record<string, never>>
) =>
  Object.keys(requested).reduce(
    (missing: Record<ClientId, NamespaceList>, clientId) => {
      if (!received[clientId]) {
        missing[clientId] = requested[clientId];
        return missing;
      }
      const missingNamespaces = requested[clientId].filter(
        (namespace) => !Object.keys(received[clientId]).includes(namespace)
      );
      if (missingNamespaces.length > 0) {
        missing[clientId] = missingNamespaces;
      }
      return missing;
    },
    {}
  );

export type UnsubscribeFn = () => void;

class UserDataRequest<
  TUserAttributes extends UserAttributes,
  TStorage extends Record<ClientId, Record<string, string>>,
  TAttributes extends Array<keyof TUserAttributes>,
  TRequestedStorage extends Record<keyof TStorage, NamespaceList>,
  TSubscribeResponse extends Record<string, unknown> & { state: State } = {
    state: State;
  },
> {
  static FETCH_TIMEOUT: Millis = 10000;
  private static WITH_NON_USER_ACCESS = true;

  private fetchTimeoutId: NodeJS.Timeout | number;
  private lastReceivedData?: Record<string, unknown>;
  private subscriptionEventListeners: Array<UnsubscribeFn> = [];
  private resolveNonUserAccess = false;

  constructor(
    private requestedAttributes?: TAttributes,
    private requestedStorage?: TRequestedStorage,
    private requestedNonUserAccess = false,
    private context = 'unknown'
  ) {}

  withContext(context: string) {
    this.context = context;
    return this;
  }

  withAttributes<NewAttributes extends Array<keyof TUserAttributes>>(
    attributes: NewAttributes
  ) {
    if (!Array.isArray(attributes)) {
      throw new TypeError('attributes must be an array');
    }
    return new UserDataRequest<
      TUserAttributes,
      TStorage,
      Array<TAttributes[number] & NewAttributes[number]>,
      TRequestedStorage,
      TSubscribeResponse & {
        attributes: Pick<
          TUserAttributes,
          TAttributes[number] & NewAttributes[number]
        >;
      }
    >(
      [...(this.requestedAttributes || ([] as TAttributes)), ...attributes],
      this.requestedStorage,
      this.requestedNonUserAccess,
      this.context
    );
  }

  withStorage(namespaces: Array<string>) {
    if (!Array.isArray(namespaces)) {
      throw new TypeError('namespaces must be an array');
    }
    return new UserDataRequest<
      TUserAttributes,
      TStorage,
      TAttributes,
      TRequestedStorage,
      TSubscribeResponse & {
        storage: Record<string, Record<string, string>>;
      }
    >(
      this.requestedAttributes,
      {
        ...this.requestedStorage,
        [DEFAULT_CLIENT_ID]: [
          ...((this.requestedStorage &&
            this.requestedStorage[DEFAULT_CLIENT_ID]) ||
            []),
          ...namespaces,
        ],
      },
      this.requestedNonUserAccess,
      this.context
    );
  }

  withClientStorage(clientId: keyof TStorage, namespaces: Array<string>) {
    if (typeof clientId !== 'string') {
      throw new TypeError('clientId must be of type string');
    }
    if (!Array.isArray(namespaces)) {
      throw new TypeError('namespaces must be an array');
    }
    return new UserDataRequest<
      TUserAttributes,
      TStorage,
      TAttributes,
      TRequestedStorage,
      TSubscribeResponse & {
        clientStorage: Record<ClientId, (typeof namespaces)[number]>;
      }
    >(
      this.requestedAttributes,
      {
        ...this.requestedStorage,
        [clientId]: [
          ...((this.requestedStorage && this.requestedStorage[clientId]) || []),
          ...namespaces,
        ],
      },
      this.requestedNonUserAccess,
      this.context
    );
  }

  withNonUserAccess() {
    return new UserDataRequest<
      TUserAttributes,
      TStorage,
      TAttributes,
      TRequestedStorage,
      TSubscribeResponse & {
        nonUserAccess: Array<Record<string, string>>;
      }
    >(
      this.requestedAttributes,
      this.requestedStorage,
      UserDataRequest.WITH_NON_USER_ACCESS,
      this.context
    );
  }

  subscribe(onData: (data: TSubscribeResponse) => void): UnsubscribeFn {
    if (typeof onData !== 'function') {
      throw new TypeError('onData must be a function');
    }
    let previousData = this.setupLocalCache();
    if (isRunningOnLocalhost()) {
      onData(previousData);
      return;
    }
    this.subscriptionEventListeners.push(
      listenForAllEvents(AmediaUserEvents.ON_STATE_UPDATED, (evt) => {
        const state = evt.detail;
        if (
          Object.keys(previousData.state).length > 0 &&
          deepEquals(previousData.state, state)
        ) {
          return;
        }

        previousData = { ...previousData, state };
        onData(previousData);
      })
    );
    dispatch(new CustomEvent(AmediaUserEvents.REQUEST_STATE));

    if (this.requestedNonUserAccess) {
      this.subscriptionEventListeners.push(
        listenForAllEvents(AmediaUserEvents.ON_NON_USER_ACCESS, (evt) => {
          const nonUserAccess = evt.detail;
          if (
            previousData.nonUserAccess &&
            deepEquals(previousData.nonUserAccess, nonUserAccess)
          ) {
            return;
          }
          previousData = {
            ...previousData,
            nonUserAccess,
          };
          onData(previousData);
        })
      );
      dispatch(new CustomEvent(AmediaUserEvents.REQUEST_NON_USER_ACCESS));
    }

    if (
      Array.isArray(this.requestedAttributes) &&
      this.requestedAttributes.length > 0
    ) {
      this.subscriptionEventListeners.push(
        listenForAllEvents(AmediaUserEvents.ON_ATTRIBUTES_UPDATED, (evt) => {
          const responseAttributes = this.processUserAttributesEvent(evt);

          if (
            Object.keys(previousData.attributes).length > 0 &&
            deepEquals(previousData.attributes, responseAttributes)
          ) {
            return;
          }

          previousData = { ...previousData, attributes: responseAttributes };
          onData(previousData);
        })
      );
      dispatch(
        new CustomEvent(AmediaUserEvents.REQUEST_USER_ATTRIBUTES, {
          detail: [...this.requestedAttributes, 'uuid'],
        })
      );
    }

    if (
      this.requestedStorage &&
      Object.keys(this.requestedStorage).length > 0
    ) {
      this.subscriptionEventListeners.push(
        listenForAllEvents(AmediaUserEvents.ON_STORAGE_UPDATED, (evt) => {
          const responseStorage = this.processStorageEventDetail(evt.detail);
          if (deepEquals(previousData.storage, responseStorage.default || {})) {
            return;
          }

          const responseStorageClients = Object.keys(responseStorage).filter(
            (c) => c !== DEFAULT_CLIENT_ID
          );
          previousData = {
            ...previousData,
            ...(responseStorage.default && {
              storage: responseStorage.default,
            }),
            ...(responseStorageClients.length > 0 && {
              clientStorage: responseStorageClients.reduce(
                (clientStorage: typeof responseStorage, clientId) => {
                  clientStorage[clientId] = responseStorage[clientId];
                  return clientStorage;
                },
                {}
              ),
            }),
          };
          onData(previousData);
        })
      );
      dispatch(
        new CustomEvent(AmediaUserEvents.REQUEST_STORAGE_NAMESPACES, {
          detail: this.requestedStorage,
        })
      );
    }

    return () => this.unsubscribe();
  }

  fetch(options?: { timeout?: Millis }): Promise<TSubscribeResponse> {
    const start = Date.now();

    return new Promise<TSubscribeResponse>((resolve, reject) => {
      if (isRunningOnLocalhost()) {
        reject(new Error('@amedia/user cannot run on localhost'));
      }

      // Set a limit for how long we will accept waiting for data
      this.startFetchTimeout(reject, options?.timeout);

      this.subscribe((data: TSubscribeResponse) => {
        if (isInEmergencyMode(EmergencyMode.Aid)) {
          this.clearFetchTimeout();
          this.unsubscribe();
          reject(new EmergencyModeError());
          return;
        }

        // In case of slow network conditions we increase waiting time when some data is received
        this.restartFetchTimeout(reject, options);
        this.lastReceivedData = data;

        if (typeof data.state.isLoggedIn === 'undefined') {
          return;
        }

        if (data.state.isLoggedIn === false) {
          if (
            (this.requestedAttributes?.includes('extraData') &&
              !Object.keys(data.attributes).includes('extraData')) ||
            (this.requestedNonUserAccess &&
              !Object.keys(data).includes('nonUserAccess'))
          ) {
            return; // ExtraData and NonUserAccess are valid even for logged-out users
          }
          resolve(data);
          return;
        }

        if (
          isMissingAttributes(
            this.requestedAttributes,
            data.attributes as never
          ) ||
          (this.requestedStorage?.default &&
            isMissingAttributes(
              this.requestedStorage.default,
              data.storage as never
            )) ||
          (this.requestedStorage &&
            isMissingClientStorage(
              Object.fromEntries(
                Object.entries(this.requestedStorage || {}).filter(
                  ([clientId]) => clientId !== DEFAULT_CLIENT_ID
                )
              ),
              (data.clientStorage || {}) as never
            )) ||
          (this.requestedNonUserAccess &&
            !Object.keys(data).includes('nonUserAccess'))
        ) {
          return;
        }

        resolve(data);
      });
    }).then((data) => {
      this.debugLogFetchCompleted(start, data);
      this.clearFetchTimeout();
      this.unsubscribe();
      return data;
    });
  }

  private startFetchTimeout(
    promiseRejectCallback: (error: FetchTimeoutError) => void,
    timeout?: number
  ) {
    this.fetchTimeoutId = setTimeout(() => {
      this.unsubscribe();
      const missingAttributes = findMissingAttributes(
        this.requestedAttributes,
        this.lastReceivedData?.attributes
      );
      const missingStorage = findMissingAttributes(
        this.requestedStorage?.default || [],
        this.lastReceivedData?.storage
      );
      const missingClientStorage = findMissingStorage(
        Object.fromEntries(
          Object.entries(this.requestedStorage || {}).filter(
            ([clientId]) => clientId !== DEFAULT_CLIENT_ID
          )
        ),
        (this.lastReceivedData?.clientStorage || {}) as never
      );
      const missing = JSON.stringify({
        ...(this.requestedNonUserAccess &&
          !Object.keys(this.lastReceivedData).includes('nonUserAccess') && {
            nonUserAccess: null,
          }),
        ...(missingAttributes.length > 0 && { attributes: missingAttributes }),
        ...(missingStorage.length > 0 && { storage: missingStorage }),
        ...(Object.keys(missingClientStorage).length > 0 && {
          clientStorage: missingClientStorage,
        }),
      });
      promiseRejectCallback(
        new FetchTimeoutError(
          `@amedia/user timed out while waiting for ${
            missing === '{}' ? 'login state to resolve' : missing
          }`,
          this.lastReceivedData || {}
        )
      );
    }, timeout || UserDataRequest.FETCH_TIMEOUT);
  }

  private clearFetchTimeout() {
    if (this.fetchTimeoutId) {
      clearTimeout(<NodeJS.Timeout>this.fetchTimeoutId);
    }
  }

  private restartFetchTimeout(
    reject: (error: FetchTimeoutError) => void,
    options: { timeout?: Millis }
  ) {
    this.clearFetchTimeout();
    this.startFetchTimeout(reject, options?.timeout);
  }

  private unsubscribe() {
    while (this.subscriptionEventListeners.length > 0) {
      const unsubscribe = this.subscriptionEventListeners.pop();
      unsubscribe();
    }
  }

  private processUserAttributesEvent(evt: CustomEvent<UserAttributes>) {
    const userAttributes = evt.detail as TUserAttributes;

    // Reduce responseAttributes to match requested requestedAttributes
    return (
      this.requestedAttributes &&
      this.requestedAttributes.reduce((map, attribute) => {
        if (userAttributes[attribute] !== undefined) {
          map[attribute] = userAttributes[attribute];
        }
        return map;
      }, {} as TUserAttributes)
    );
  }

  private processStorageEventDetail(
    storage: Record<ClientId, Record<string, Record<string, string>>>
  ): Record<ClientId, Record<string, Record<string, string>>> {
    return Object.keys(this.requestedStorage || []).reduce(
      (clients, clientId) => ({
        ...clients,
        [clientId]:
          storage[clientId] &&
          this.requestedStorage[clientId]
            .filter((namespace) => storage[clientId][namespace])
            .reduce(
              (namespaces: Record<string, Namespace>, namespace) => ({
                ...namespaces,
                [namespace]: ProxiedNamespace(
                  new Namespace({
                    name: namespace,
                    clientId,
                    data: storage[clientId][namespace],
                  })
                ),
              }),
              {}
            ),
      }),
      {}
    );
  }

  private setupLocalCache() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let previousData: SubscribeResponse = {
      state: {},
    };
    if (this.requestedAttributes && this.requestedAttributes.length > 0) {
      previousData = { ...previousData, attributes: {} };
    }

    if (
      this.requestedStorage &&
      Object.keys(this.requestedStorage).includes('default')
    ) {
      previousData = { ...previousData, storage: {} };
    }

    if (
      this.requestedStorage &&
      Object.keys(this.requestedStorage).filter((n) => n !== 'default').length >
        0
    ) {
      previousData = { ...previousData, clientStorage: {} };
    }
    return previousData;
  }

  private debugLogFetchCompleted(start: number, data: TSubscribeResponse) {
    try {
      if (
        Object.keys(data).length === 0 ||
        !(
          new URLSearchParams(location.search).has('debug') ||
          sessionStorage.getItem('amedia-user:debug') ||
          localStorage.getItem('amedia-user:debug')
        )
      ) {
        return;
      }
    } catch {
      // Accessing sessionStorage or localStorage may trigger an error.
      // Ignore and just treat as disabled.
      return;
    }
    const time = Date.now() - start;
    const requestedDataArgs: unknown[] = [];
    let requestedData: string;
    if (this.requestedAttributes) {
      requestedData = `Requested attributes: %o. Response: %o`;
      requestedDataArgs.push(this.requestedAttributes, data.attributes);
    }
    if (this.requestedStorage) {
      if (requestedData) requestedData += '\n';
      requestedData += 'Requested storage: %o. Response:, %o';
      requestedDataArgs.push(this.requestedStorage, data.storage);
    }
    if (!requestedData) {
      requestedData = 'Only checked for login status';
    }
    const stateArgs: unknown[] = [];
    if (data.state.isLoggedIn) {
      stateArgs.push(
        'font-weight: bold; color:green;',
        'Logged in',
        'font-weight: initial; color: initial'
      );
    } else {
      stateArgs.push(
        'font-weight: bold; color:orange;',
        'Guest',
        'font-weight: initial; color: initial'
      );
    }
    let emergencyString = '';
    const emergencyArgs = [];
    if (data.state.emergencyMode) {
      emergencyString = ` %cActive emergency modes: %c[${data.state.emergencyMode.join(
        '. '
      )}]%c.`;
      emergencyArgs.push(
        'font-weight: bold;',
        'color: orange;',
        'color: initial'
      );
    }
    console.debug(
      `%c@amedia/user%c UserDataRequest %c(context: ${this.context})%c\n${requestedData}\nFulfilled in: %c%dms%c. %cStarted %dms after page load.%c\nState: %c%s%c.${emergencyString}`,
      'color: #EC008B;',
      'color: initial;',
      'color: gray;',
      'color: initial;',
      ...requestedDataArgs,
      `color: #${time > 50 ? 'FF9334' : time > 100 ? 'F00' : '0F0'};`,
      time,
      'color: initial;',
      'color: gray;',
      start - loaded,
      'color: initial;',
      ...stateArgs,
      ...emergencyArgs
    );
  }
}

export default UserDataRequest;
