import { dispatch, listenWithExclusivity } from '../utils/customEventWrapper';
import { readAndParseVSTOKEN2Cookie } from '../utils/vstoken2AccessCookieParser';
import { NonUserAccess } from '../types';
import { setNonUserAccessCookie } from '../actions/authorization';
import { getCurrentSiteDomain } from '../utils/url';

import { AmediaUserEvents } from './eventMap';

export const listenForNonUserAccessRequests = () => {
  listenWithExclusivity(AmediaUserEvents.REQUEST_NON_USER_ACCESS, async () => {
    const nonUserAccess: Array<NonUserAccess> = [];
    let vsToken2 = readAndParseVSTOKEN2Cookie();
    if (!vsToken2) {
      try {
        await setNonUserAccessCookie(getCurrentSiteDomain());
        vsToken2 = readAndParseVSTOKEN2Cookie();
      } catch {
        // Ignore error
      }
    }
    if (vsToken2?.accessFeatures.length > 0) {
      nonUserAccess.push({
        accessFeatures: vsToken2.accessFeatures,
        customer: vsToken2.customer,
      });
    }
    dispatch(
      new CustomEvent(AmediaUserEvents.ON_NON_USER_ACCESS, {
        detail: nonUserAccess,
      })
    );
  });
};
