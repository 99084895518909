import * as rt from 'runtypes';
import { ValidationError } from 'runtypes';

import log from '../utils/log';
import { schemaVerifiedFetch } from '../utils/fetcher';
import IgnoredNetworkError from '../exceptions/IgnoredNetworkError';
import ResponseError from '../exceptions/ResponseError';

export enum AffiliationType {
  ACCESS = 'access',
  FAMILY = 'family',
  SUBSCRIPTION = 'subscription',
}

const PrimarySiteSchema = rt.Record({
  domain: rt.String,
  name: rt.String,
  affiliation: rt.Literal(AffiliationType.ACCESS),
  affiliations: rt.Array(
    rt.Union(
      rt.Literal(AffiliationType.ACCESS),
      rt.Literal(AffiliationType.FAMILY),
      rt.Literal(AffiliationType.SUBSCRIPTION)
    )
  ),
});

export type PrimarySite = rt.Static<typeof PrimarySiteSchema>;

export const getPrimarySite = (
  accessFeatures: string[]
): Promise<PrimarySite | null> =>
  schemaVerifiedFetch(
    rt.Union(rt.Null, PrimarySiteSchema),
    `/api/shamo/v2/access/primary_site?${new URLSearchParams({
      access_features: accessFeatures.join(','),
    })}`,
    {
      method: 'get',
    }
  ).catch((error): null => {
    if (error instanceof IgnoredNetworkError) {
      return null;
    }
    if (error instanceof ValidationError) {
      return null;
    }
    if (error instanceof ResponseError) {
      log.error(
        `Failed to get primary site for user: Request not ok (${error.response.status}: ${error.response.statusText}).`
      );
      return null;
    }
    throw error;
  });
